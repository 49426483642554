<template>
    <div id='errorsRow'>
        <CRow>
          <CCol md="2">
          </CCol>
          <CCol md="8">
            <CCard>
              <CCardHeader>
                <a-icon type="frown" />
                <strong> Something went wrong...</strong>
              </CCardHeader>
              <CCardBody>
                <CListGroup >
                  <div v-for="(error) in errorsList" :key="error.message">
                    <div id='contactRow'>
                      <CListGroupItem
                          class="flex-column align-items-start"
                      >
                        <CRow>
                          <CCol>
                            {{error.message}}
                          </CCol>
                        </CRow>
                      </CListGroupItem>
                    </div>
                  </div>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
    </div>
</template>

<script>

export default {
    name: 'Errors',
    components: {
        
    },
    methods: {
    },
    props: {
        errorsList: Array
    }
}

</script>


