<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <div v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
            </div>
        </div>
        <div v-if="!fetching && communication==null">
        <CRow>
          <CCol>
        <CCard>
        <CCardHeader>
            <a-icon type="frown" /><strong> Sorry! </strong>
        </CCardHeader>
        <CCardBody>
            <p>Couldn't find this Communication record</p>
        </CCardBody>
        </CCard>
          </CCol>
        </CRow>
        </div>
        <div v-if="communication">
        <CRow>
          <CCol>
        <CCard>
        <CCardHeader>
            <CIcon name="cil-justify-center"/><strong> {{ communication.communicationType }} </strong>
        </CCardHeader>
        <CCardBody>
        <ul v-if="!fetching && communication==null">
            <p>Communication {{ $route.params.communicationId }} not found</p>
        </ul>
        <div v-if="communication">

            <div v-if="communication.communicationType == 'Email'">
            <CRow class="communication-row">
              <CCol>
            <div class="d-flex w-100 justify-content-between">
                <div>
                <a-icon type="mail" /> &nbsp; {{communication.emailTo}}
                </div>
                <small>{{ communication.createdDate | formatDateAgo }}</small>
            </div>
              </CCol>
            </CRow>
            </div>

            <div v-if="communication.communicationType == 'Email'">
            <CRow class="communication-row">
              <CCol>
                <strong>Subject: </strong> {{communication.emailSubject}}
              </CCol>
            </CRow>
            </div>
            <div v-if="communication.communicationType == 'Email' && communication.emailBodyHtml">
            <CRow class="communication-row">
            <CCol>
            <div class="border-box" v-html="communication.emailBodyHtml">
            </div>
            </CCol>
            </CRow>
            </div>

            <div v-if="communication.communicationType == 'Email' && !communication.emailBodyHtml && communication.emailBodyText">
            <CRow class="communication-row">
            <CCol>
                {{communication.emailBodyText}}
            </CCol>
            </CRow>
            </div>

            <div v-if="communication.communicationType == 'Phone'">
            <CRow class="communication-row">
              <CCol>
            <div class="d-flex w-100 justify-content-between">
                <div>
                <a-icon type="phone" /> &nbsp; {{communication.phone}}
                </div>
                <small>{{ communication.createdDate | formatDateAgo }}</small>
            </div>
              </CCol>
            </CRow>
            </div>

            <div v-if="communication.communicationType != 'Email'">
            <CRow class="communication-row">
              <CCol>
              {{ communication.message }}
              </CCol>
            </CRow>
            </div>

        </div>
        </CCardBody>
        </CCard>
          </CCol>
        </CRow>
        </div>
        </CCol>
        </CRow>

        <div v-if="errors && errors.length">
          <Errors 
              :errorsList="errors" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Errors from '@/components/Errors.vue'
export default {
name: 'Communication',
components: {
  Errors
},
  data() {
    return {
      communication: null,
      errors: [],
      fetching: false
    }
  },
  methods: {
    fetchCommunication () {
        console.log('fetch communication now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('communicationId: ' + this.$route.params.communicationId)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/communications/' + this.$route.params.communicationId;
        console.log('url: ' + url)
        console.log('fetch communication')
        this.fetching = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Communication, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch communication, response: ' + JSON.stringify(response.data))
                        this.communication = response.data
                        this.fetching = false;
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch communication, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch communication, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                    })
            }
        })
    },
    convertHtmlToText(html) {
      // Create a new div element
      console.log('convert to text: ' + html)
      var tempDivElement = document.createElement("div");

      // Set the HTML content with the given value
      tempDivElement.innerHTML = html;

      // Retrieve the text property of the element 
      let text = tempDivElement.textContent || tempDivElement.innerText || ""
      console.log('CONVERTED to: ' + text)
      return text.replace('null',' ');
    },
    emailBodyTextFromHtml() {
      return this.convertHtmlToText(this.emailBodyHtml)
    }

  },
  // Fetches posts when the component is created.
  mounted() {
        console.log('call fetchCommunication!!!')
        this.fetchCommunication()
        console.log('Communication:created')
  }
//   ,
//   beforeRouteUpdate (to, from, next) {
//         console.log('call fetch!!!')
//         this.fetchOpportunity()
//         console.log('Opportunity:beforeRouteUpdate')
//         this.$refs.topLoader.done();
//         console.log(to);//not even this
//         next();
//   }
}
</script>

<style>
.communication-row {
    padding-bottom: 10px;
}

.border-box {
    border: 1px solid #ccc!important;
    padding: 4px;
    margin-bottom: 4px;
}
</style>
